@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

.bg-med-blue-grad {
    background: linear-gradient(to bottom, #1C2D5A, #31839A);
}
.bg-dark-blue-grad {
    background: linear-gradient(to bottom, #1C2D5A, #172038);
}

.bg-blue-green-grad, .bg-blue-green-grad-timeline {
    background: radial-gradient(#B2D33F, #3EA6C3);
}
.bg-blue-green-grad-timeline:hover {
    background: radial-gradient(#3EA6C3, #FFF) !important;
}
.bg-blue-yellow-grad, .bg-blue-yellow-grad-timeline {
    background: radial-gradient(#02ACBF, #F4DD36);
}
.bg-blue-yellow-grad-timeline:hover {
    background: radial-gradient(#FFE000, #FFF) !important;
}
.bg-blue-light-grad, .bg-blue-light-grad-timeline {
    background: radial-gradient(#64DCFE, #1E46B1);
}
.bg-blue-light-grad-timeline:hover {
    background: radial-gradient(#0066FF, #FFF) !important;
}
.bg-red-yellow-grad, .bg-red-yellow-grad-timeline {
    background: radial-gradient(#B01800, #F4DD36);
}
.bg-red-yellow-grad-timeline:hover  {
    background: radial-gradient(#F2330F, #FFF) !important;
}


.bg-green-blue-dark-grad {
    background-color: #2C4B65;
    background-image: radial-gradient(
        at -6% 6%,
        #B2D33E 0%,
        #31839A 24%,
        transparent 45%
    ),
    radial-gradient(60% 140% at 100% 30%, #31839A 30%, #2C4B65 60%, transparent 85%);
}
.bg-blue-red-light-grad {
    background-color: #F2330F;
    background-image: radial-gradient( 
        at -6% 6%,
        #31839A 0%,
        #983222 24%,
        transparent 75%
    ),
    radial-gradient(60% 140% at 105% 45%, #31839A 0%, #983222 40%, transparent 80%);
}

.bg-red-graphic {
    background-color: #983222;
    background-image: url('/images/graphic-overlay.png');
    background-blend-mode: multiply;
}
.bg-dark-blue-graphic {
    background-color: #1C2D5A;
    background-image: url('/images/graphic-overlay.png');
    background-blend-mode: multiply;
}
.bg-light-blue-graphic {
    background-color: #31839A;
    background-image: url('/images/graphic-overlay.png');
    background-blend-mode: multiply;
}
.bg-dark-green-graphic {
    background-color: #274E25;
    background-image: url('/images/graphic-overlay.png');
    background-blend-mode: multiply;
}


/*
"bg-med-blue-grad", --done
"bg-dark-blue-grad", --done
"bg-green-blue-dark-grad", --done
".bg-blue-red-light-grad", --done
"bg-red-yellow-grad", --done
"bg-blue-light-grad", --done
"bg-blue-yellow-grad", --done
"bg-blue-green-grad", --done
"bg-dark-blue-graphic",
"bg-light-blue-graphic",
"bg-red-graphic",
"bg-dark-green-graphic",
*/